import React, { Component, Fragment} from 'react'
import Collapse, { Panel } from 'rc-collapse'
// import '../assets/css/tvp.css'

const TargetMediaWidth = "(max-width: 600px)"
export default class TvpClientSection extends Component {
    constructor(props){
      super(props)
      this.state={
        matchTargetWidth: typeof window !== 'undefined' && window.matchMedia(TargetMediaWidth).matches
      }
    }
    contents=[
      {name:{zh:'冠仁（香港）有限公司',en:'CHAMPION SOLUTION (HONGKONG) LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'研學美肌有限公司',en:'BIOCARE BEAUTY LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'香港體育會（集團）控股有限公司',en:'HONGKONG SPORTS ASSOCIATION (GROUP) HOLDINGS LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'城安企業有限公司',en:'CITYGRAND ENTERISES LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'健康之路有限公司',en:'THE PATH OF HEALTH LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},

      {name:{zh:'寶寧健有限公司',en:'BIOHEALTH PLUS LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'巨盈體育會有限公司',en:'TVP GIANT SPORTS CLUB LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'Mixx Collection Ltd',en:''}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'海外升學中心集團有限公司',en:'STUDY OVERSEAS GROUP LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'莊臣集團綠色害蟲管理有限公司',en:'Johnson Group Pest Specialist Limited'}, img:require('../assets/images/tvp/company_icon_ssy.png')},

      {name:{zh:'成興集團貿易有限公司',en:'SHING HING GROUP TRADING LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'亮詩有限公司',en:'LUX LUXE LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'傑博諮詢有限公司',en:'KEYPOINT CONSULTING LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'',en:'FACTORY\'S COMPANY'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'盛海工程有限公司',en:'GRAND OCEAN ENGINEERING DESING COMPANY LIMITED'}, img:require('../assets/images/tvp/company_icon_ssy.png')},

      {name:{zh:'',en:'Trivet Company Limited'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'天下一原班人影視製作有限公司',en:'One Cool Jibsson Video Productions Limited'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'',en:'Ah D Transportation Company Limited'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      {name:{zh:'',en:'Empathy Beauty Company Limited'}, img:require('../assets/images/tvp/company_icon_ssy.png')},
      
    ]
    componentDidMount(){
      const matchMediaWidthHander = (e)=>{this.setState({matchTargetWidth: e.matches})}
      window.matchMedia(TargetMediaWidth).addListener(matchMediaWidthHander);
    }

    render() {
        let items=[]
        items = this.contents.map((data,index)=> this.renderItems(data,index))

        return (
            <div className="col-12">
            <header className="major">
                  <h3>尊貴客戶</h3>
            </header>
            <div
            // style={this.state.matchTargetWidth?Styles.widthLess400:Styles.container}
            >
            {items}
            </div>
            </div>
        )
    }

    renderItems=(data,index)=>{
      return(
        <div key={index}style={Styles.itemContainer}>

        <div style={Styles.itemName}>{data.name.zh||data.name.en}</div>
        {
          // <img src={data.img} style={Styles.iconStyle}/>
        }
        </div>
      )
    }
}



const Styles={
  container:{
    display:'grid',
    gridGap: '1.5em 1.5em',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  widthLess400:{
    display:'grid',
    gridGap: '1.5em 0em',
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  itemContainer:{
    margin:'0px 20px 20px 0px',
    display:'inline-block',
    // flex:0,
    border:'1px solid #dddddd',
    // maxWidth:220,
    paddingLeft:20,
    paddingRight:20,
    height:46,
    borderRadius:4,
    justifyContent:'center',
    alignItems:'center',
  },
  itemName:{
    fontSize:18,
    textAlign:'center',
    // border:'1px solid #ff0000',
    display:'flex',
    flex:1,
    height:'100%',
    width:'100%',
    justifyContent:'center',
    alignItems:'center',
  },


  iconStyle:{
    width:158,
    height:60,
  },
}
