import React, { Component, Fragment} from 'react'
import Collapse, { Panel } from 'rc-collapse'
// import '../assets/css/tvp.css'

const TargetMediaWidth = "(max-width: 600px)"
export default class TvpServiceSection extends Component {
    constructor(props){
      super(props)
      this.state={
        matchTargetWidth: typeof window !== 'undefined' && window.matchMedia(TargetMediaWidth).matches
      }
    }
    contents=[
      {title:'車隊訂單配送管理系統',
      img:require('../assets/images/tvp/delivery_system.png'),
      content:'系統可記錄所有送貨車隊之司機、車輛號碼、日期、時間、送貨數量、所收貨款。司機可按需要新增或删除當天之送訂單資料。總公司可隨時查核有關司機之配送訂單狀況，當天司機之出勤表、所收貨款之對賬表及日結單。 相關報表可匯出至EXCEL格式，方便用作進一步分析之用。'},
      {title:'人力資源系統',
      img:require('../assets/images/tvp/hr_system.png'),
      content:'我們的雲端人力資源管理系統，幫助各規模和各行業的企業將人力資源電子化，員工及管理階層都能隨時登入雲端系統查看所有資料。系統包含僱員檔案/合約、考勤管理、薪酬管理、員工假期、報銷費用、培訓、招聘、評核表現。數據電子化，提昇公司資源調配的效率。'},
      {title:'客戶關係管理 CRM',
      img:require('../assets/images/tvp/crm.png'),
      content:'CRM系統可幫助企業有效地規劃營銷策略。 利用收集每位潛在客戶資料及信息，系統將客戶數據進行整合及分析。從而進行銷售預測、客群分析、社群互動。\n企業內各部門及相關人士可分享客戶資訊，以提升更完善的客戶服務。'},
      {title:'EDMS',
      img:require('../assets/images/tvp/edms.png'),
      content:'利用EDMS實行無紙化工作，減少室內文件儲存空間。系統可儲存30萬頁之文檔。相關工作人員可隨時在系統內擷取所需文件及將文檔分享給指定人仕。可設定用戶權限，保障重要文件不會隨意披露。可設定多種文件分類，方便快速搜尋，提昇工作效益。'},
      {title:'ERP 企業資源系統',
      img:require('../assets/images/tvp/erp.png'),
      content:'ERP系統能整合企業內部資源、管理及處理各部門之工作流程。我方的ERP系統整合企業的銷售、採購及庫存管理之流程。每個模組彼此間互相交流，共享同一資料庫。流程自動化，庫存量按採購及銷售單自動化調整。系統更可有保存期管理。系統配置多個分析報表。'},

      {title:'手機預約系統',
      img:require('../assets/images/tvp/booking.png'),
      content:'企業可利用預約系統讓客戶自行登記預約服務，減低人手登記工作。針對智能手機的普及，客戶可隨時隨地在手機登記預約服務。系統可設置預約時段、服務名稱、提供服務地點及企業最新消息。'},
      {title:'order/ invoice system',
      img:require('../assets/images/tvp/order_invoice.png'),
      content:'訂單／銷售發票系統具有用戶/客戶端文件管理，可創建/編輯/刪除訂單發票，可以按訂單狀態排序。如果需要，可向管理員發送警報電子郵件提醒及可按類型，類別電子文件存儲，將不同的文件上傳到服務器，並可以讓客戶端下載。'},
      {title:'工程管理APP',
      img:require('../assets/images/tvp/engineering.png'),
      content:'工程管理手機程式設有ios 及android 版本，可供員工登入及可即時跟進工程的進度。及提供後台CMS 可讓管理員創建/更新用戶及工作任務，可分配工作任務給用戶，及查看用戶簽入記錄。'},
      {title:'Video conference app\n(With Course feature)',
      img:require('../assets/images/tvp/video_course.png'),
      content:'網頁版視像課程設有登陸註冊，課程清單，詳情，報名及會員付款和註冊歷史，手機程式視像課程可供會員登陸註冊，查看已註冊的課程/視頻，課程時間表及已註冊的課程。設有後台CMS 可以管理課程視頻，會員註冊和付款狀態。'},
      {title:'video conference app',
      img:require('../assets/images/tvp/video_conference.png'),
      content:'私人視頻會議手機及網站平台，設有前端及後端。前端會員可選擇會議室加入視頻，在線聊天和文件共享。後端用戶可查看會員及聊天室信息記錄。'},
    ]
    componentDidMount(){
      const matchMediaWidthHander = (e)=>{this.setState({matchTargetWidth: e.matches})}
      window.matchMedia(TargetMediaWidth).addListener(matchMediaWidthHander);
    }

    render() {
        let items=[]
        items = this.contents.map((data,index)=> this.renderItems(data,index))
        // console.log(this.state)
        return (
            <div className="col-12">
            <header className="major">
                  <h3>服務</h3>
            </header>
            <div style={this.state.matchTargetWidth?Styles.widthLess400:Styles.container}>
            {items}
            </div>
            </div>
        )
    }

    renderItems=(data,index)=>{
      return(
        <div key={index}style={Styles.itemContainer}>
        <div style={Styles.titleContainer}>
        <img src={data.img} style={Styles.iconStyle}/>
        <div style={Styles.itemTitle}>{data.title}</div>
        </div>
        <div style={Styles.itemContent}>{data.content}</div>
        </div>
      )
    }
}



const Styles={
  container:{
    display:'grid',
    gridGap: '1.5em 1.5em',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  widthLess400:{
    display:'grid',
    gridGap: '1.5em 0em',
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  itemContainer:{
    border:'1px solid #eeeeee',
    padding:20,
  },
  itemTitle:{
    fontSize:18,
    marginLeft:10,
  },
  itemContent:{
    marginTop:10,
  },
  titleContainer:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
  },
  iconStyle:{
    width:60,
    height:60,
  },
}
