import React from "react"
import Helmet from 'react-helmet'
import '../assets/scss/main.scss'
import TvpContentSection from '../components/TvpContentSection'
import CtaSection from '../components/CTASection'
import SliderBanner from '../components/SliderBanner'
import GlobalValues from '../components/GlobalValues'

class TvpPage extends React.Component {
  render() {
    let imageList =[]
    // for (var i = 0; i < 3; i++) {
      imageList.push(require("../assets/images/sealcave_banner_tvp2.png"))
    // }
    return (
      <div>
        <Helmet title="資助計劃 (TVP)" >
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="keywords" content="Sealcave, Seal Cave, TVP顧問, TVP顧問服務, 科技券, 科技券顧問服務, 科技券顧問, 科技券供應商, Technology Voucher Programme,科技券申請,政府科技支援,科技券先導計劃,中小企支援,科技券計劃,tvp fund,申請科技券,tvp funding,BUD資助,政府科技券資料,政府資助40萬,政府資助計劃,科技券資助計劃,中小企資助,政府資助,tvp,tvp申請,科技券先導,政府支援服務" />
        <meta name="description" content="Professional TVP/ BUD Funding consulting service help over hundred clients overcome obstacles successfully."></meta>
        {
          <script async src={GlobalValues.gtag_link}></script>
        }
        {
          <script>
            {GlobalValues.gtag_config}
          </script>
        }

        </Helmet>
        <SliderBanner images={imageList}/>
        {
        // <TvpContentSection />  
        }
        <CtaSection />
      </div>
    )
  }
}
export default TvpPage
