import React, { Component, Fragment} from 'react'
import Collapse, { Panel } from 'rc-collapse'

export default class TvpMoreInfoSection extends Component {
    render() {
        return (
            <div className="col-12">
            <header className="major">
                  <h3>更多資料</h3>
                </header>
                <Collapse accordion={true}>
                    <Panel header="透過科技券計劃網站提交申請的所需文件" showArrow={false}>
                        <ul>
                            <li>申請企業的有效商業登記證副本</li>
                            <li>代表申請企業簽署申請表格的人士的 有效香港身份證或護照副本</li>
                            <li>
                                證明申請企業在香港有實質業務運作的證明文件副本:
                                <ul>
                                    <li>例如僱員記錄、報稅表、業務合約、發票等</li>
                                </ul>
                            </li>
                            <li>
                                申請企業的商業登記署表格:
                                <ul>
                                    <li>有效商業登記證副本或公司註冊處周年申報表 (表格NAR1)核證副本</li>
                                </ul>
                            </li>
                            <li>競投者提交的相關報價單核證副本 (須顯示發出機構的詳細聯絡資料)。</li>
                            <li>申請企業須於每份報價單上清楚列明涉及的貨品／服務。</li>
                        </ul>
                    </Panel>
                    <Panel header="符合以下條件的企業均可提出申請" showArrow={false}>
                        <ul>
                            <li>根據《商業登記條例》(第310章)在香港登記；或</li>
                            <li>根據《公司條例》(第622章)在香港註冊成立的公司；或</li>
                            <li>根據有關條例在香港成立的法定機構及並非政府資助機構*或任何政府資助機構*的附屬公司及在提交申請時在本港有實質業務運作，而該業務須與申請項目相關。</li>
                            *政府資助機構指接受政府經常資助的機構。有關資助用作支付這些機構為公眾提供服務的運作開支。經常資助可能佔有關機構收入的很大部分，或僅屬小額的供款╱贊助，在有關機構的總收入中佔一個很小的比例。
                        </ul>
                    </Panel>
                    <Panel header="資助範圍" showArrow={false}>
                        <p>科技券計劃就科技服務和方案為項目提供資助，以助申請企業提高生產力或升級轉型。</p>
                        <p>科技券計劃涵蓋的典型科技服務及／或方案列表:</p>

                        <b>(I) 生產力／工序</b>
                        <ul>
                            <li>預約安排及輪候管理系統</li>
                            <li>擴增實境技術系統</li>
                            <li>大數據及雲端分析方案</li>
                            <li>建築資訊模型系統</li>
                            <li>診所管理系統</li>
                            <li>網絡安全方案</li>
                            <li>文件管理及流動存取系統</li>
                            <li>電子庫存管理系統</li>
                            <li>電子採購管理系統</li>
                            <li>企業資源規劃方案</li>
                            <li>客戶關係管理 系統</li>
                            <li>客戶及會員分析管理系統</li>
                            <li>人力資源管理 系統</li>
                            <li>車隊管理系統</li>
                            <li>定位服務</li>
                            <li>物流管理系統</li>
                            <li>銷售點管理系統</li>
                            <li>快速回應管理系統</li>
                            <li>實時生產追蹤系統</li>
                            <li>協助企業符合生產標準的方案</li>
                        </ul>
                        <b>(II) 檢測和認證</b>
                        <ul>
                            <li>能源管理體系 (ISO 50001)</li>
                            <li>環境管理體系 (ISO 14001)</li>
                            <li>資訊安全管理體系 (ISO/IEC 27001)</li>
                        </ul>
                        <b>(III) 環境保護</b>
                        <ul>
                            <li>能源管理系統</li>
                            <li>廢物管理技術 包括：</li>
                            <li>玻璃內爆器</li>
                            <li>自動塑膠分類系統</li>
                        </ul>
                    </Panel>
                    <Panel header="資助金額是多少？" showArrow={false}>
                        <ul>
                            <li>科技券會以3:1的配對模式，向每名合資格申請者提供最多60萬港元資助。申請者必須以現金投入不少於核准項目總成本四分之一的資金。科技券會以發還款項方式向申請者提供不多於項目實際成本四分之三的資助。每名合資格申請者最多可獲批六個項目，惟累計資助上限為60萬港元。為確保能專注推行項目，申請者不得同時進行多於一個科技券項目。獲批的科技券項目下的開支，不得接受其他本地公共資助來源的資助。</li>
                        </ul>
                    </Panel>
                    <Panel header="項目期限有沒有限制?" showArrow={false}>
                        <ui>
                            <li>每個項目一般應在12個月內完成。</li>
                        </ui>
                    </Panel>
                    <Panel header="科技券計劃提供的資助款項可用於以下項目" showArrow={false}>
                        <ul>
                            <li>科技顧問服務</li>
                            <li>購買、租用或訂購屬於項目必要組成部分的訂製設備／硬件、軟件及科技服務或方案</li>
                            <li>購買、租用或訂購屬於項目必要組成部分的現成設備／硬件、軟件及科技服務或方案，但不應超逾項目成本的50%</li>
                            <li>項目審計(適用於核准資助額超過五萬港元的項目)，惟獲計算入項目總成本的審計費用上限為3,000港元</li>
                        </ul>
                    </Panel>
                </Collapse>
            </div>
        )
    }
}