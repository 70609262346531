import React, { Component, Fragment} from 'react'
import Collapse, { Panel } from 'rc-collapse'
// import '../assets/css/tvp.css'

const TargetMediaWidth = "(max-width: 600px)"
export default class TvpIndustrySection extends Component {
    constructor(props){
      super(props)
      this.state={
        matchTargetWidth: typeof window !== 'undefined' && window.matchMedia(TargetMediaWidth).matches
      }
    }
    tags=[
      {title:'物流/運輸',},
      {title:'貿易',},
      {title:'房地產業/地產',},
      {title:'車隊管理',},
      {title:'醫療服務',},
      {title:'美容服務',},
      {title:'工程/建造',},
      {title:'零售',},
      {title:'批發服務',},
      {title:'飲食/食肆',},
      {title:'專業服務',},
      {title:'生產',},
    ]
    componentDidMount(){
      const matchMediaWidthHander = (e)=>{this.setState({matchTargetWidth: e.matches})}
      window.matchMedia(TargetMediaWidth).addListener(matchMediaWidthHander);
    }

    render() {
        let items=[]
        items = this.tags.map((data,index)=> this.renderItems(data,index))

        return (
            <div className="col-12">
            <header className="major">
                  <h3>應用行業</h3>
            </header>
            <div
            // style={this.state.matchTargetWidth?Styles.widthLess400:Styles.container}
            >
            {items}
            </div>
            </div>
        )
    }

    renderItems=(data,index)=>{
      return(
        <div key={index}style={Styles.itemContainer}>

        <div style={Styles.itemTitle}>{data.title}</div>

        </div>
      )
    }
}



const Styles={
  container:{
    display:'grid',
    gridGap: '1.5em 1.5em',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  widthLess400:{
    display:'grid',
    gridGap: '1.5em 0em',
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  itemContainer:{
    margin:'0px 20px 20px 0px',
    display:'inline-block',
    // flex:0,
    border:'1px solid #dddddd',
    // maxWidth:220,
    paddingLeft:20,
    paddingRight:20,
    height:46,
    borderRadius:4,
    justifyContent:'center',
    alignItems:'center',
  },
  itemTitle:{
    fontSize:18,
    textAlign:'center',
    // border:'1px solid #ff0000',
    display:'flex',
    flex:1,
    height:'100%',
    width:'100%',
    justifyContent:'center',
    alignItems:'center',
  },


  iconStyle:{
    width:60,
    height:60,
  },
}
